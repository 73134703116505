<template>
	<div class="page-container">
		<div class="header">
			<page-header back @navBack="navBack" :pageTitle="pageTitle">
				<!-- <template v-slot:default>
				  <van-icon size="20" name="question-o" />
				</template> -->
			</page-header>
		</div>

		<div class="page-body">
			<!-- 步骤 -->
			<div class="search-wrap">
				<van-search
					v-model="searchName"
					shape="round"
					background="#f5f5f5"
					placeholder="请输入城市名"
					@search="onSearch"
					@clear="onClear"
				/>
			</div>
			<div v-show="isSearch" class="content">
				<div class="rencent">
					<div class="van-index-anchor">搜索结果</div>
					<div class="city-list">
						<span v-for="city in searchList" @click="selectCity(city.cityCode,city.cityName)"
							  :key="city.cityCode" class="city-wrap">{{ city.cityName }}</span>
					</div>
				</div>
			</div>
			<div v-show="!isSearch" class="content">
				<!-- 内容 -->

				<div class="location">
					<div class="city-list">
						<div class="van-index-anchor">定位</div>
						<span
							v-if="locationState"
							@click="selectCity(location.cityCode,location.cityName)"
							class="city-wrap"
						>
							{{ location.cityName }}
						</span>
						<span v-else class="failed city-wrap">获取定位失败</span>
					</div>
				</div>
				<div v-if="historyCity&&historyCity.length" class="rencent">
					<div class="van-index-anchor">最近访问</div>
					<div class="city-list">
						<span v-for="city in historyCity" @click="selectCity(city.cityCode,city.cityName)"
							  :key="city.cityCode" class="city-wrap">{{ city.cityName }}</span>
					</div>
				</div>

				<van-index-bar :index-list="indexList">
					<template v-for="(item,index) in indexList">
						<van-index-anchor :key="item" :index="item"/>
						<div :key="index" class="city-list">
							<span v-for="city in cityList[index]" @click="selectCity(city.cityCode,city.cityName)"
								  :key="city.cityCode" class="city-wrap">{{ city.cityName }}</span>
						</div>
					</template>


				</van-index-bar>

			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '../../components/page-header.vue';
import {getCitys, getCitysByName, getCitysByCode} from "../../api/user";

export default {
	data() {
		return {
			pageTitle: '切换城市',
			timer: null,
			historyCity: [],
			//  indexList: ['热门','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
			indexList: [],
			cityList: [],
			searchName: '',
			searchList: [],
			isSearch: false,
			location: {cityName: '', cityCode: ''},
			locationState: false
		}
	},
	components: {
		PageHeader,
	},
	mounted() {
		if (window.curCity) {
			const {cid} = window.curCity;
			this.location.cityCode = cid.slice(0, cid.length - 2) + '00';
			this.searchByCode();
		}
		this.historyCity = JSON.parse(sessionStorage.getItem('historyCity')) || [];
		this.getCitys();
	},
	methods: {
		getCitys() {
			getCitys().then(res => {
				const data = res;
				for (const key in data) {
					if (Object.hasOwnProperty.call(data, key)) {
						const el = data[key];
						this.indexList.push(key);
						this.cityList.push(el);
					}
				}
				this.indexList.unshift('热门');
				this.cityList.unshift([
					{cityName: '北京市', cityCode: '110100'},
					{cityName: '上海市', cityCode: '300100'},
					{cityName: '广州市', cityCode: '440100'},
					{cityName: '深圳市', cityCode: '440300'},
				]);
			})
		},
		onSearch() {
			if (!this.searchName.trim()) return;
			getCitysByName(this.searchName).then(res => {
				this.searchList = res;
				this.isSearch = true;
			})
		},
		searchByCode() {
			if (!this.location.cityCode) return;
			getCitysByCode(this.location.cityCode).then(res => {
				if (res && res.length) {
					this.location.cityName = res[0].cityName;
					this.locationState = true
				}
			})
		},
		selectCity(code, name) {
			if (!this.historyCity.find(v => v.cityCode == code)) {
				this.historyCity.unshift({cityCode: code, cityName: name})
			}
			if (this.historyCity.length > 3) {
				this.historyCity.splice(this.historyCity.length - 1, 1)
			}

			sessionStorage.setItem('historyCity', JSON.stringify(this.historyCity))
			const query = this.$route.query || {}
			query.step = 1
			query.code = code
			query.name = name
			this.$router.push({path: "/auth", query});
		},
		onClear() {
			this.isSearch = false;
			this.searchList = [];
		},
		navBack() {
			this.$router.push({path: "/auth", query: {step: 1}});
		}
	}
}
</script>

<style lang="less">

.van-index-bar__index {
	padding-bottom: 4px;
	color: #ff8506;
}

.city-list {
	position: relative;
	margin: 10px 4px;

	&::after {
		content: '';
		position: absolute;
		left: 15px;
		right: 15px;
		bottom: 0;
		height: 1px;
		background: #ededed;
	}
}

.city-wrap {
	display: inline-block;
	margin: 0 8px 15px;
	background: #fff;
	font-size: 14px;
	color: #333;
	border-radius: 40px;
	padding: 8px 24px;
}

.location {
	.city-list {
		display: flex;
		justify-content: space-between;
	}

	.city-wrap {
		margin-right: 50px;
		color: #fff;
		background: linear-gradient(90deg, #FCA142, #FF8506);

		&.failed {
			background: #ddd;
		}
	}
}

.van-index-anchor--sticky {
	color: #FF8506;
}

.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;

	.van-index-bar__index {
		color: @colorMain;
	}

	.location {
		.city-wrap {
			background: linear-gradient(90deg, @colorSecond, @colorMain);
		}
	}

	.van-index-anchor--sticky {
		color: @colorMain;
	}
}

.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;

	.van-index-bar__index {
		color: @colorMain;
	}

	.location {
		.city-wrap {
			background: linear-gradient(90deg, @colorSecond, @colorMain);
		}
	}

	.van-index-anchor--sticky {
		color: @colorMain;
	}
}

</style>
<style lang="less" scoped>
.page-container {
	min-height: 100vh;
	background: #f5f5f5;
}

.page-body {
	margin-top: 0;

	.van-search__content {
		background: #fff;
	}

	.content {
		position: relative;
		top: 0;
		border-radius: 0;
		background: transparent;
	}
}

.header {
	padding-bottom: 0;

	.page-header {
		color: #333;
		background: #fff !important;
		padding: 8px 20px;
	}
}
</style>
